<template>
  <v-card :loading="isLoading">
    <v-card-title>KVKK Onayı</v-card-title>
    <v-card-subtitle>
      <strong
        >RoboSAY'ı kullanabilmeniz için KVKK metnini onaylamanız
        gerekmektedir.</strong
      >
    </v-card-subtitle>
    <v-card-text>
      <div class="consent-text" v-html="text"></div>
      <v-form @submit.prevent="handleFormSubmit()">
        <div class="text-right mt-7">
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="green"
            type="submit"
            class="ms-3"
          >
            Onaylıyorum
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  min-height: 100vh;
}

.consent-text {
  border: 1px solid black;
  max-height: 50vh;
  overflow: auto;
  padding: 20px;
}
</style>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "ConsentPage",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.loadText();
  },
  data() {
    return {
      text: null,
    };
  },
  methods: {
    loadText() {
      this.isLoading = true;

      this.$api
        .get("api-residents/users/consent")
        .then((response) => {
          this.text = response.data.data.text.replace(/\n/g, "<br>");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      this.isLoading = true;

      this.$api
        .post("api-residents/users/consent")
        .then(() => {
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
